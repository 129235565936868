/* Navbar text and icon consistency */
.navbar .nav-link,
.navbar .dropdown-toggle,
.navbar .dropdown-item {
    font-size: 1rem !important;
}

/* Override Bootstrap’s .btn styles on dropdown toggles */
.navbar .dropdown-toggle {
    font-size: 1rem !important; /* Ensure consistency with Nav.Link */

}

/* Consistent icon size */
.navbar .nav-link svg,
.navbar .dropdown-toggle svg,
.navbar .dropdown-item svg {
    width: 1rem;
    height: 1rem;
    
}

/* Dropdown menu styling */
.navbar .dropdown-menu {
    font-size: 1rem !important;
    
}

/* Remove excessive button padding */
.navbar .no-border {
    border: none !important;
    
}

/* Hover effect for dropdown items */
.navbar .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Prevent unwanted focus styles */
.navbar .dropdown-toggle:focus,
.navbar .dropdown-toggle:active {
    box-shadow: none !important;
}
