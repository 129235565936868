.invoice-modal .modal-dialog {
  max-width: 210mm;
}

.invoice-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 100%;
  height: 297mm; /* A4 height */
  max-height: 297mm; 
  overflow: auto;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.company-info {
  text-align: left;
  margin-bottom: 20px;
  font-size: 12px;
}

.invoice-meta {
  text-align: left;
  font-size: 12px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  font-size: 12px;
}

.invoice-table th {
  padding: 4px;
  border-bottom: 1px solid #000;
  text-align: right;
}

.invoice-table td {
  padding: 4px;
  border-bottom: 1px solid #ddd;
  text-align: right;
}



.seller-name {
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  padding: 4px;
}

.invoice-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 12px;
}

.invoice-summary .totals {
  text-align: left;
}

.payment-details {
  text-align: left;
}

.payment-advice {
  border-top: 1px dashed #000;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 12px;

}

.advice-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.advice-details, .advice-summary {
  display: flex;
  justify-content: space-between;
}

.advice-summary {
  text-align: right;
}
