:root {
    --bs-primary: #67816C !important;
    --bs-secondary: #7A8B80 !important;
}

/* Primary/Secondary button overrides */
.btn-primary {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:disabled {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary) !important;
}

/* Basic layout and utility classes */
.content {
    padding-top: 60px;
}

.checkmark {
    float: right;
    font-size: 0.8em;
    line-height: 1.5;
}

.no-border {
    border: none !important;
    box-shadow: none !important;
}

.logo {
    font-size: 90px;
    animation: fade-in-out 4s infinite;
    transform: rotate(90deg);
    color: var(--bs-primary);
}

@keyframes fade-in-out {

    0%,
    100% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.9;
    }
}

.loading-container,
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.login-container {
    width: 500px;
}

.loading-icon {
    font-size: 90px;
    color: var(--bs-primary);
    animation: fade-in 5s forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Table responsiveness */
.table-responsive {
    overflow-x: auto;
}

/* Hide columns on smaller screens */
@media (max-width: 768px) {

    .col-productType,
    .col-productGrouping,
    .col-orderStatus,
    .col-createdAt,
    .col-totalPrice {
        display: none;
    }
}

@media (max-width: 576px) {

    .col-price,
    .col-uom {
        display: none;
    }
}

/* Off-canvas menu custom styles */
.offcanvas {
    max-width: 80% !important;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.dropdown-toggle.no-border {
    border: none;
    background: none;
}

.dropdown-menu {
    background-color: inherit;
}

.navbar-nav .nav-link {
    padding: 0.5rem 1rem;
}

@media (min-width: 992px) {
    .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991px) {
    .desktop-nav {
        display: none;
    }
}

/* Dropdown active item override */
.dropdown-item.active {
    background-color: var(--bs-secondary) !important;
}

/* Order card styling */
.order-card {
    border: 1px solid #ccc;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    background: #f8f9fa;
}

.order-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Grid containers */
.grid-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
}

.grid-title {
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Packing SKU styles */
.packed-sku-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-radius: 5px;
    margin-top: 5px;
}

.packed-sku-content {
    display: flex;
    flex-direction: column;
}

.add-button {
    margin-left: auto;
}

.remove-button {
    margin-left: 10px;
}

.packed-input {
    width: 100%;
    margin-top: 5px;
}

.save-status {
    font-size: 18px;
    color: gray;
    display: flex;
    align-items: center;
    gap: 5px;
}

/* General form-control and button font sizes */
.form-control {
    font-size: 0.8rem !important;
    border: solid 1px #cecece !important;
}



/* -----------------------------------------------------------------------------------
     1) Modal-Compact styles: smaller fonts & spacing for table-based modals
  ----------------------------------------------------------------------------------- */
.modal-compact .modal-content {
    font-size: 0.80rem;
    padding: 10px 15px;
}

.modal-compact .compact-form-table td {
    padding: 4px;
    vertical-align: middle;
}

.modal-compact .form-control,
.modal-compact .btn {
    font-size: 0.80rem;
    padding: 0.3rem 0.45rem;
}

/* -----------------------------------------------------------------------------------
     2) SKU-Wide-Modal: tall/wide modals with pinned header/footer, scrolling body
  ----------------------------------------------------------------------------------- */
.sku-wide-modal .modal-dialog {
    max-width: 90vw;
    /* wide */
    margin: auto;
}

.sku-wide-modal .modal-content {
    max-height: 90vh;
    /* tall */
    display: flex;
    flex-direction: column;
    font-size: 0.80rem;
}

/* .modal-body scrolls if content > 90vh */
.sku-wide-modal .modal-body {
    overflow-y: auto;
}

/* Optionally smaller form controls within .sku-wide-modal */
.sku-wide-modal .modal-body .form-control {
    font-size: 0.8rem !important;
}

/* -----------------------------------------------------------------------------------
     3) Another variation: Modal-Wide-Tall
  ----------------------------------------------------------------------------------- */
.modal-wide-tall .modal-dialog {
    max-width: 800px !important;
    /* override Bootstrap's defaults */
    width: 100% !important;
    /* ensures it occupies full width up to 800px */
    margin: auto;
}

.modal-wide-tall .modal-content {
    display: flex;
    flex-direction: column;
    height: 90vh;
    /* or whatever height you like */
}

.modal-wide-tall .modal-body {
    flex: 1 1 auto;
    overflow-y: auto;
    /* scrolls if content is taller than the body area */
}

/* -----------------------------------------------------------------------------------
     4) "Jira-like" field group styles
  ----------------------------------------------------------------------------------- */
.jira-field-group {
    padding: 6px 0;
    margin-bottom: 6px;
}

.jira-field-label {
    display: block;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 2px;
    color: #333;
}

/* -----------------------------------------------------------------------------------
     5) React-Select smaller styles (shared classes)
  ----------------------------------------------------------------------------------- */
.my-select__control {
    min-height: 30px;
    font-size: 0.8rem;
    border-radius: 4px;
}

.my-select__value-container {
    padding: 0 6px;
}

.my-select__single-value,
.my-select__placeholder {
    margin: 0;
    font-size: 0.8rem;
    color: #c66565;
}

.my-select__option {
    font-size: 0.8rem;
    padding: 6px 10px;
}


@media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px !important;
        margin-right: auto;
        margin-left: auto;
    }
}


.big-toggle-switch .form-check-input {
    transform: scale(1.6);
    /* Adjust as needed */
    transform-origin: left center;
    /* So it grows to the right/down */
}


.border-danger {
    border: 1.5px solid red !important;
    /* Ensure the red border is applied */
}

.back-button {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 32px !important;  /* Match other btn-sm buttons */
    padding: 4px 8px !important;
    font-size: 0.875rem !important;
    border-radius: 4px !important;
}